<template>
  <section class="section">
    <div class="yszc-wrapper-mobile">
      <div class="yszc-panel">
        <div class="yszc-lft">
          <div
            :class="{ title: true, 'pre-hide': true, 'move-bottom1': isShowDH }"
          >
            {{ qyhmInfo.title1 }}
          </div>
          <div
            :class="{
              subTitle: true,
              'pre-hide': true,
              'move-bottom2': isShowDH,
            }"
          >
            {{ qyhmInfo.subTitle1 }}
          </div>
          <div
            :class="{
              'step-cell': true,
              'pre-hide': true,
              'move-bottom3': isShowDH,
            }"
          >
            <div class="step-item step-1">
              <div class="img"></div>
              <div class="txt">{{ qyhmInfo.step1[0] }}</div>
            </div>
            <div class="line"></div>
            <div class="step-item step-2">
              <div class="img"></div>
              <div class="txt">{{ qyhmInfo.step1[1] }}</div>
            </div>
            <div class="line"></div>
            <div class="step-item step-3">
              <div class="img"></div>
              <div class="txt">{{ qyhmInfo.step1[2] }}</div>
            </div>
          </div>
          <div
            :class="{
              'smty-cell': true,
              'pre-hide': true,
              'move-bottom4': isShowDH,
            }"
          >
            <div class="qrcode"></div>
            <div class="txt">扫码体验</div>
            <div class="sjx"></div>
          </div>
        </div>
        <div
          :class="{
            'yszc-rgt': true,
            'pre-hide': true,
            'move-bottom1': isShowDH,
          }"
        >
          <div class="sj"></div>
          <div class="yszc-yy"></div>
        </div>
        
      </div>
    </div>
    
    <!-- VS模块 -->
    <div class="vs-wrapper">
      <div class="vs-img"></div>
    </div>
  </section>
</template>

<script>
import {  qyhmInfo } from '@/config'

export default {
  name: 'Product',
  computed: {
    qyhmInfo() {
      return qyhmInfo
    },
  },
  data() {
    return {
      isShowDH: true,
    }
  },
  inject: ['getPosition', 'scrollTo'],
  methods: {
    onScroll() {
      const { scrollTop } = this.getPosition()
      const clientHeight = document.documentElement.clientHeight
      if (clientHeight <= 1150) {
        if (scrollTop >= 200) {
          this.isShowDH = true
        }
        
      } else {
        this.isShowDH = true
        
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, true)
    const clientHeight = document.documentElement.clientHeight
    if (clientHeight > 1150) {
      this.isShowDH = true
    }
    if (this.$route.query && this.$route.query.from === 'kbs') {
      setTimeout(() => {
        const y = this.$refs.zncsWrap.getBoundingClientRect().top + 180
        this.scrollTo({y})
      }, 500)
    }
    
    
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
}
</script>

<style scoped lang="less">
.yszc-wrapper-mobile {
  width: 100%;
  height: 1693px;
  background: #fff;
  position: relative;
  z-index: 2;
  // mar-bottom: 222px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  .yszc-panel {
    width: 750px;
    padding-top: 156px;
    position: relative;
    .yszc-lft {
      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 54px;
        color: #102A55;
        line-height: 56px;
        margin-bottom: 48px;
        text-align: center;
      }
      .subTitle {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 30px;
        color: #102A55;
        margin-bottom: 50px;
        text-align: center;
        // opacity: 0.6;
      }
      .step-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 116px;
        .line {
          flex-shrink: 1;
          width: 52px;
          height: 1px;
          border-top: 1px solid #c3daff;
          margin: 0 38px;
          margin-top: -30px;
        }
        .step-item {
          text-align: center;
          // margin-right: 55px;
          .img {
            display: block;
            width: 88px;
            height: 88px;
            background-image: url('../assets/images/2.0/step1-1.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 0 auto;
            margin-bottom: 18px;
          }
          .txt {
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #102a55;
          }
        }
        .step-2 {
          .img {
            background-image: url('../assets/images/2.0/step1-2.png');
          }
        }
        .step-3 {
          .img {
            background-image: url('../assets/images/gshm/step1-3.png');
          }
        }
      }
      .smty-cell {
        margin-bottom: 64px;
        .qrcode {
          width: 200px;
          height: 200px;
          background-image: url('../assets/images/2.0/yszc.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin: 0 auto;
          margin-bottom: 8px;
        }
        .txt {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #102a55;
          opacity: 0.6;
          line-height: 40px;
          text-align: center;
        }
      }
    }
    .yszc-rgt {
      position: relative;
      .sj {
        margin-left: 56px;
        width: 478px;
        height: 585px;
        background-image: url('../assets/images/gshm/yszc-sj-m.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .yszc-yy {
    position: absolute;
    right: -87px;
    bottom: -200px;
    width: 609px;
    height: 609px;
    background-image: url('../assets/images/gshm/yszc-sj-yy.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // transform: rotate(102deg);
    // opacity: .57;
  }
}
.vs-wrapper {
  width: 100%;
  height: 1704px;
  background: #fff;
  position: relative;
  z-index: 2;
  margin-bottom: 50px;
  .vs-img {
    width: 630px;
    height: 1704px;
    background-image: url('../assets/images/gshm/vs-img-mobile.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
  }
}


.pre-hide {
  transform: translateY(100px);
  opacity: 0;
}
.move-bottom1 {
  animation: move-bottom 0.8s forwards ease-in-out;
}
.move-bottom2 {
  animation: move-bottom 0.8s 0.1s forwards ease-in-out;
}
.move-bottom3 {
  animation: move-bottom 0.8s 0.2s forwards ease-in-out;
}
.move-bottom4 {
  animation: move-bottom 0.8s 0.3s forwards ease-in-out;
}

.left-hide {
  transform: translateX(-150px);
  opacity: 0;
}
.right-hide {
  transform: translateX(150px);
  opacity: 0;
}
.move-left1 {
  animation: move-left 0.5s 0s forwards ease-in-out;
}
.move-right1 {
  animation: move-right 0.5s 0s forwards ease-in-out;
}

@keyframes move-bottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes move-left {
  0% {
    transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes move-right {
  0% {
    transform: translateX(150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
</style>
