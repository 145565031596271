<template>
  <section class="section" style="position: relative">
    <div class="section-box" style="position: relative">
      <div class="banner-wrapper">
        <Navgation :isPageHome="true" />

        <div class="option-panel">
          <div class="banner-bg"></div>
          <div class="option-panel-box">
            <div class="option-title">{{ title }}</div>
            <div class="option-sub-title">{{ subTitle }}</div>
            <div :class="{ 'option-cell': true }">
              <div class="form-cell">
                
                <div class="hm-cell" >
                  <div class="form-item width1">
                    <el-cascader
                      v-model="form.address"
                      placeholder="请选择住所（经营场所）"
                      :options="optionsTree"
                      :props="cascaderProps"
                      @change="handleChangeTree"
                      :append-to-body="false"
                    ></el-cascader>
                  </div>
                  <div class="form-item width2 form-ml">
                    <el-cascader
                      :disabled="disabledXzqh"
                      v-model="form.xzqh"
                      :placeholder="xzqhPlace"
                      :options="xzqhOptionList"
                      :props="cascaderProps"
                      :append-to-body="false"
                    ></el-cascader>
                  </div>
                  <div class="form-item width3 form-ml">
                    <el-input
                      v-model="form.qyName"
                      placeholder="请输入字号，如“十角兽”"
                    ></el-input>
                  </div>
                  <div class="form-item width4 form-ml" v-if="form.industryCode !== 'zdyhy'">
                    <el-cascader
                      v-model="form.industry"
                      placeholder="请选择行业，如“网络科技”"
                      :options="optionsIndustry"
                      :props="cascaderProps2"
                      @change="handleChangeIndustry"
                      :show-all-levels="false"
                      @visible-change="visibleChange"
                      popper-class="cascader-class"
                      :append-to-body="false"
                    >
                  </el-cascader>
                  </div>
                  <div class="form-item width4 form-ml" @click="handleFocus" v-else>
                    <el-input
                      v-model="form.industryName"
                      placeholder="请输入"

                    ></el-input>
                  </div>
                  <div class="form-item width5 form-ml">
                    <el-cascader
                      v-model="form.organizationForm"
                      placeholder="请选择组织形式"
                      :options="optionsOrganization"
                      :props="cascaderProps2"
                      :append-to-body="false"
                    ></el-cascader>
                  </div>
                </div>
                <div class="qyqc-box" >
                  <span v-if="collectCompany">企业名称：{{collectCompany}}</span>
                </div>
                <div class="submit-btn" data-agl-cvt="5" @click="handleSubmit">{{ btnTxt }}</div>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    </div>
    
    <!-- 登录二维码 -->
    <QrCodeModal
      :QrCodeDialogVisible="qrVisible"
      @closeQr="closeQr"
      v-if="qrVisible"
    >
    </QrCodeModal>
    
    
    <!-- 手机号 -->
    <HmPhoneForm :hmLoading="hmLoading" :hmPhoneFormVisible="hmPhoneFormVisible" @confirmHmPhoneForm="confirmHmPhoneForm" @closeHmPhoneForm="closeHmPhoneForm"></HmPhoneForm>
    
    <!-- 行业类别 -->
    <HmHyForm :hmLoading="hmLoading" :hmHyFormVisible="hmHyFormVisible" @confirmHmHyForm="confirmHmHyForm" @closeHmHyForm="closeHmHyForm"></HmHyForm>
    
    
  </section>
</template>

<script>
import { syBannerInfo, qmInfo, cmInfo, hmInfo } from "@/config";
import Modal from "@/components/modal";
import Navgation from "@/layouts/components/Navgation";
import homeApi from "@/api/home.api.js";
import hmApi from "@/api/hm.api.js";
import QrCodeModal from "@/views/homePopup/qrCode.vue";
import TheNameResultModal from "@/views/homePopup/theNameResult.vue";
import NameCheckResult from "@/views/homePopup/nameCheckResult.vue";
import CmResult from "@/views/homePopup/cmResult.vue";
import auth from "@/utils/auth.js";
import dayjs from "dayjs";
import HmPhoneForm from "@/views/homePopup/hmPhoneForm.vue";
import HmHyForm from "@/views/homePopup/hmHyForm.vue";

export default {
  name: "BannerhmMobile",
  data() {
    return {
      visible: false,
      form: {
        address: "",
        industry: "",
        city: "",
        province: "",
        companyName: "",
        industryName:'',
        organizationForm: "有限公司",
      },
      companyNameReal: "",
      optionsTree: [],
      optionsIndustry: [],
      optionsOrganization:[],
      xzqhOptionList: [],
      cascaderProps: {
        label: "name",
        value: "code",
        children: "children",
      },
      cascaderProps2: {
        label: "name",
        value: "code",
        children: "children",
      },
      page: 0, // 起名第一批
      currentIndex: 1,
      title: "企业注册免费核名",
      subTitle: "直连工商官网，准确率99%",
      btnTxt: "提交核名",
      isShowXD: false,
      isShowXD2: false,
      value: "",
      input: "",
      interval: null, //定时获取token
      qrVisible: false, //二维码弹窗开关
      qrUrl: "", //二维码
      
      hmResult: {},
      clickType: "", //mfqm免费签名  cyc测一测  tjhm提交核名
      showLoading: false,
      scrollTimer: null,
      oldScroll: 0,
      newScroll: 0,
      isShowAdsorption: false,
      disabledXzqh: true,
      xzqhPlace: '请先选择住所(经营场所)',
      hmPhoneFormVisible: false, //手机号弹窗开关
      hmLoading: false, //核名loading
      bd_vid: 'fzwk',
      hmHyFormVisible: false, //行业类别弹窗开关
    };
  },
  inject: ["getPosition", "scrollTo"],
  computed: {
    collectCompany() {
      if (this.form.xzqh && this.form.qyName && this.form.industryName && this.form.organizationForm ) {
        const name = this.form.xzqh + this.form.qyName + this.form.industryName + this.form.organizationForm
        return name
      } else {
        return ''
      }
      
    }
  },
  methods: {
    // 埋点
    batchSave(type) {
      const refId = location.href.split("refId=")[1];
      const funcCodes = {
        起名: 101,
        测名: 102,
        核名: 103,
        工商注册: 104,
        打开代账机器人: 105,
      };
      if (location.href.includes("refId=")) {
        homeApi.batchSave([
          {
            apptype: 3,
            refId,
            triggertime: dayjs().format(),
            channel: "",
            companyId: "",
            companyName: "",
            createtime: "",
            datatype: "",
            deviceheight: "",
            devicetype: "",
            devicewidth: "",
            functioncode: funcCodes[type],
            functionname: type,
            id: "",
            imei: "",
            networktype: "",
            osname: "",
            pagecode: "",
            pageurl: "",
            phonenumber: "",
            platform: "",
            position: "",
            resource: "",
            sceneId: "",
            traceid: "",
            userId: "",
          },
        ]);
      }
    },
    
    async handleSubmit() {
      if (!this.form.address) {
        this.$message.error("请选择所在地区");
      } else if (!this.form.qyName) {
        this.$message.error("请输入名称");
      } else if (
        this.form.qyName.length < 2 ||
        this.form.qyName.length > 10
      ) {
        this.$message.error("请输入名称字数在2-10");
      } else if (!/^[\u4E00-\u9fa5]*$/g.test(this.form.qyName)) {
        this.$message.error("请输入中文名称");
      } else if (!this.form.industry) {
        this.$message.error("请选择行业");
      } else {
        // if (!auth.getAccessToken()) {
        //   this.getQr();
        //   return false;
        // }
        this.batchSave("核名");
        this.hmPhoneFormVisible = true
      }
    },
    handleFocus() {
      this.hmHyFormVisible = true
    },
    closeHmHyForm() {
      this.hmLoading = false;
      this.hmHyFormVisible = false
    },
    closeHmPhoneForm() {
      this.hmLoading = false;
      this.hmPhoneFormVisible = false
    },
    confirmHmPhoneForm(phone) {
      this.form.mobile = phone;
      this.hmLoading = true;
      this.handleHm();
      setTimeout(() => {
        this.hmLoading = false;
        this.hmPhoneFormVisible = false
        this.getXcxUrlLink()
      }, 500);
    },
    confirmHmHyForm(hylb) {
      this.form.industryCode = 'zdyhy';
      this.form.industryName = hylb;
      this.form.industry = hylb;
      this.closeHmHyForm()
    },
    async handleHm() {
      let {
        qyName,
        xzqh,
        industryName,
        organizationForm,
        address
      } = this.form;
      console.log(this.form,'kk');
      this.form.qyQc = xzqh + qyName + industryName + organizationForm
      const sendData = {
        ...this.form,
        xzqh:xzqh.toString(),
        address:null,
        industry:null,
        bdVid:this.bd_vid,
        subjectType: '公司企业'
      }
      const res = await hmApi.checkQyName(sendData);
      if (res.code === "0") {
        // 这里是核名返回结果
        // this.scrollTo({ y: 0 });
        this.form.qyName = '';
        this.hmResult = res.result;
        this.showLoading = false;
        window._agl && window._agl.push(['track', ['success', {t: 3}]])
      } else {
        this.showLoading = false;
      }
    },
    closeQr() {
      this.qrVisible = false;
    },

    
    
   
    
    async getNodeTree() {
      const res = await hmApi.getAllNodeTree({level: 3});
      if (res.code === "0") {
        this.optionsTree = this.renderTree2(res.result);
      }
    },
    async getIndustry() {
      const res = await hmApi.getIndustry();
      if (res.code === "0") {
        this.optionsIndustry = this.renderIndustry(res.result);
      }
    },
    async getConfigOrganizationForm() {
      const res = await hmApi.getConfigOrganizationForm();
      if (res.code === "0") {
        this.optionsOrganization = res.result.map(k => {
          return { code: k, name: k };
        });
      }
    },
    async buildXzqhOptions() {
      const {province, city, district} = this.form
      const res = await hmApi.buildXzqhOptions({
          city,
          district,
          province
        });
      if (res.code === "0") {
        this.disabledXzqh = false
        this.xzqhPlace = '请选择行政区划'
        this.xzqhOptionList = res.result.map(k => {
          return {
            code: k,
            name: k
          }
        });
      }
    },
    //渲染地区tree
    renderIndustry(treeData) {
      let newTreeData = [];
      for (let item of treeData) {
        newTreeData.push({
          code: item.code + '-' + item.name,
          name: item.name,
          children:
            item.children && item.children.length
              ? this.renderIndustry(item.children)
              : null,
        });
      }
      return newTreeData;
    },
    renderTree2(treeData) {
      let newTreeData = [];
      for (let item of treeData) {
        newTreeData.push({
          code: item.code + '-' + item.name,
          name: item.name,
          children:
            item.child && item.child.length
              ? this.renderTree2(item.child)
              : null,
        });
      }
      return newTreeData;
    },
    handleChangeTree(value,ops) {
      if (value) {
        value.map((k,index) => {
          const arr = k.split("-");
          if (index === 0) {
            this.form.province = arr[1];
          } else if (index === 1) {
            this.form.city = arr[1];
          } else if (index === 2) {
            this.form.district = arr[1];
            this.form.addressCode = arr[0];
          }

        })
        this.buildXzqhOptions();
      }
    },
    handleChangeIndustry(value) {
      if (value) {
        value.map((k,index) => {
          const arr = k.split("-");
          if (index === 0) {
            
          } else if (index === 1) {
            this.form.industryCode = arr[0];
            this.form.industryName = arr[1];
          }

        })
      }
    },
    async getXcxUrlLink() {
      const res = await hmApi.getXcxUrlLink({channel: 'qiyezhuce',pagePath:'/pages/hmRecord/hmRecord'});
      if (res.code === "0") {
        window.open(res.result, '_self');
      }
    },
    //添加按钮
    visibleChange(visible, refName, onClick) {
      //visible指是否展开
            if (visible) {
//查找dom
                const menu = document.querySelectorAll('.cascader-class')
                const shou = document.querySelectorAll('.el-cascader-shou')
                console.log(menu.length,shou,'看看你')
                if (menu.length == 1 && !shou.length) {
                    const el = document.createElement('div')
//创建一个div
                    el.className = ''
                    el.id = "first"
//样式
                    el.style = 'margin:10px 10px 0px 25px;background-color: white;height:34px;line-height: 34px;'
//内容 第一个按钮的id是firstBtn
//第二个按钮 id是secondBtn
                    el.innerHTML = `<div id="firstBtn" class="el-cascader-shou" style="cursor: pointer;font-size:14px">
                      找不到合适的行业？ <span class="zt-color" style="color:#2871ff">点击手动填写</span>
                        </div>`
//menu的索引值指在第几级创建
                    menu[0].appendChild(el)
//给添加出来的按钮创建点击事件 内容可以根据需要修改
                    document.getElementById('firstBtn').onclick = () => {
                        this.hmHyFormVisible = true
                    }
//第二个按钮的点击事件 事件内容是什么不重要
                    
                  }
              }
          }
  },
  mounted() {
    this.getNodeTree();
    this.getIndustry();
    this.getConfigOrganizationForm();
    if (this.$route.query && this.$route.query.bd_vid) {
      this.bd_vid = this.$route.query.bd_vid;
    }
  },
  beforeDestroy() {

  },
  components: {
    Modal,
    Navgation,
    QrCodeModal,
    HmPhoneForm,
    HmHyForm
  },
};
</script>

<style scoped lang="less">
.section {
  // height: 800px;
}
.loading-box {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  z-index: 3000;
  img {
    width: 200px;
  }
  div {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
.banner-wrapper {
  width: 100%;
  height: 1448px;
  overflow-x: hidden;
  background: linear-gradient( 132deg, #022D71 0%, #180D5A 100%);
  box-sizing: border-box;
  // display: flex;
  // justify-content: c;
  // align-items: center;
  

  .option-panel {
    width: 750px;
    height: 100%;
    margin: 0 auto;
    padding-top: 152px;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
    .option-panel-box {
      position: relative;
      z-index: 1;
    }
    .option-title {
      width: 100%;
      text-align: center;
      font-size: 54px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 40px;
      text-transform: none;
      background: linear-gradient(270deg, #51ffc4 30%, #24eaff 50%, #1464ff 65%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .option-sub-title{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 30px;
      color: #FFFFFF;
      text-align: center;
      margin-bottom: 38px;
    }
    .option-cell {
      &.prefixed {
        animation: option-top-hide 1s forwards ease-in-out;
      }
      
    }
    .qyqc-box{
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 28px;
      color: #102A55;
      line-height: 34px;
      margin-bottom: 48px;
      overflow: hidden;
    }
    .submit-btn {
      width: 580px;
      height: 90px;
      margin: 0 auto;
      background: #2871FF;
      border-radius: 10px 10px 10px 10px;
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.form-cell {
  width: 690px;
  height: 996px;
  margin: 0 auto;
  background: #FFFFFF;
  // box-shadow: 0px 0px 301px 0px #DFEBFA;
  border-radius: 26px 26px 26px 26px;
  border: 3px solid #FFFFFF;
  .qm-cell,
  .hm-cell {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // min-width: 870px;
    
    // padding: 0 3px;
    padding-top: 50px;
    .form-item {
      width: 630px;
      height: 100px;
      border-radius: 10px 10px 10px 10px;
      margin: 0 auto;
      margin-bottom: 36px;
      // border: 1px solid #D0D5DD;

      .el-select,
      .el-cascader {
        width: 100%;
        height: 100%;
        /deep/ .el-input {
          height: 100%;
          // background: transparent;
          .el-input__inner {
            height: 100%;
            // background: transparent;
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #060b4c;
            // border: 1px solid rgba(255, 255, 255, 0.25);
            border-radius: 9px;
            &::placeholder {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 28px;
              color: rgba(16,42,85,0.4);
              line-height: 34px;
              // color: red;
              letter-spacing: -1px;
            }
          }
          .el-input__suffix {
            // color: #fff;
            right: 30px;
            color: rgba(26, 26, 26, 1);
            .el-input__suffix-inner{
              display: block;
              height: 100%;
            }
            .el-input__icon {
              // color: #fff;
              color: rgba(26, 26, 26, 1);
              font-size: 40px;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          
        }
        /deep/ .el-cascader-panel{
          .el-cascader-menu{
            width: 300px;
            .el-cascader-menu__wrap{
              height: 408px
            }
          }
            .el-cascader-node__label{
              font-size:28px;
            }
            .el-cascader-node{
              height: 68px;
              line-height: 68px;
            }
        }
      }
      .el-input {
        width: 100%;
        height: 100%;
        /deep/ .el-input__inner {
          height: 100%;
          // background: transparent;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #060b4c;
          // border: 1px solid rgba(255, 255, 255, 0.25);
          // border-radius: 9px;
          &::placeholder {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(16, 42, 85, 0.4);
          }
        }
      }
    }
  }
  
}
.el-popper {
  width: 100%;
  height: 100%;
  .el-cascader-panel{
    .el-cascader-menu{
      width: 300px;
      .el-cascader-menu__wrap{
        height: 408px
      }
    }
      .el-cascader-node__label{
        font-size:28px;
      }
      .el-cascader-node{
        height: 68px;
        line-height: 68px;
      }
  }
}

.move-top {
  animation: option-top 0.5s forwards ease-in-out;
}
.move-hide {
  animation: option-top-hide 0.5s forwards ease-in-out;
}

@media (max-width: 768px) {
  .banner {
    display: flex;
    flex-direction: column;
    padding: 75px 0;
    width: auto;

    &-l {
      padding-top: 0px;
      padding-bottom: 0px;
      .title {
        padding-top: 137px;
        padding-bottom: 16px;
        font-size: 40px;
        font-family: AlibabaPuHuiTiR;
        color: #000000;
        text-align: center;
        line-height: 55px;
      }
      .sub-title {
        text-align: center;
        padding-top: 16px;
        font-size: 22px;
        font-family: AlibabaPuHuiTiL;
        color: #000000;
        line-height: 36px;
        font-family: sans-serif;
        font-weight: lighter;
        line-height: 36px;
        margin: 0 auto;
      }
      .btn-gp {
        text-align: center;
      }
      .btn {
        margin-top: 30px;
        padding: 4px 10px;
        font-size: 22px;
        text-align: center;
        font-family: AlibabaPuHuiTiR;
      }
      .banner-btn-icon {
        font-size: 30px;
      }
    }
    &-r {
      min-height: 460px;
      margin-top: 35px;
      .banner-r-shadow-1 {
        width: 444px;
        bottom: -36px;
      }
      .banner-r-shadow-2 {
        right: -31px;
        bottom: 67px;
      }
      .banner-r-shadow-3 {
        left: -34px;
        bottom: 62px;
      }
      // &-plug {

      //   &-r {
      //     // font-size: 150px;
      //   }
      // }
      &-main {
        width: 513px;
        height: auto;
      }
      &-plug-r {
        width: 150px;
      }
      &-l {
        width: 150px;
      }
    }
  }
}

@keyframes option-top {
  0% {
    transform: translateY(-300px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes option-top-hide {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-300px);
  }
}
</style>
