var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"yszc-wrapper-mobile"},[_c('div',{staticClass:"yszc-panel"},[_c('div',{staticClass:"yszc-lft"},[_c('div',{class:{ title: true, 'pre-hide': true, 'move-bottom1': _vm.isShowDH }},[_vm._v(" "+_vm._s(_vm.qyhmInfo.title1)+" ")]),_c('div',{class:{
            subTitle: true,
            'pre-hide': true,
            'move-bottom2': _vm.isShowDH,
          }},[_vm._v(" "+_vm._s(_vm.qyhmInfo.subTitle1)+" ")]),_c('div',{class:{
            'step-cell': true,
            'pre-hide': true,
            'move-bottom3': _vm.isShowDH,
          }},[_c('div',{staticClass:"step-item step-1"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.qyhmInfo.step1[0]))])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"step-item step-2"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.qyhmInfo.step1[1]))])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"step-item step-3"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.qyhmInfo.step1[2]))])])]),_c('div',{class:{
            'smty-cell': true,
            'pre-hide': true,
            'move-bottom4': _vm.isShowDH,
          }},[_c('div',{staticClass:"qrcode"}),_c('div',{staticClass:"txt"},[_vm._v("扫码体验")]),_c('div',{staticClass:"sjx"})])]),_c('div',{class:{
          'yszc-rgt': true,
          'pre-hide': true,
          'move-bottom1': _vm.isShowDH,
        }},[_c('div',{staticClass:"sj"}),_c('div',{staticClass:"yszc-yy"})])])]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vs-wrapper"},[_c('div',{staticClass:"vs-img"})])
}]

export { render, staticRenderFns }