<template>
  <div class="name-check-result">
    <el-dialog
      title=""
      :visible.sync="hmPhoneFormVisible"
      destroy-on-close
      :custom-class="isMobile?'hm-phone-dialog-mobile':'hm-phone-dialog'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      append-to-body
    >
      <div class="name-check-result-dialog-body">
        <img
          class="close-icon"
          src="@/assets/images/home/close.png"
          alt=""
          @click="closeHmPhoneForm"
        />
        <div class="title">提示</div>
        <div class="dialog-tip">
          <div class="i-icon"><i class="el-icon-info"></i></div>
          <div class="hint">非常重要！真实有效的号码才能收到核名结果</div>
        </div>
        <div class="form-item">
          <el-input
            v-model="form.mobile"
            placeholder="请输入您的手机号码"
          ></el-input>
        </div>
        <!-- <div class="submit-btn" @click="handleSubmit">提交核名</div> -->
        <el-button class="submit-btn" type="primary" :loading="hmLoading" @click="handleSubmit">提交核名</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import homeApi from '@/api/home.api.js'
import checkIsMobile from '@/mixin/checkIsMobile'
export default {
  props: ['hmPhoneFormVisible', 'hmLoading'],
  mixins: [checkIsMobile],
  data() {
    return {
      passRate: '',
      form:{
        mobile: ''

      }
    }
  },
  created() {},
  watch: {
    hmResult() {
      if (this.nameCheckResultVisible) {
        this.passRate = this.hmResult
      }
    },
  },
  methods: {
    closeHmPhoneForm() {
      this.form.mobile = ''
      this.$emit('closeHmPhoneForm')
      this.passRate = ''
    },
    handleSubmit() {
      if (!this.form.mobile) {
        this.$message.error("请输入您的手机号码");
      } else if (this.form.mobile.length !== 11 || !this.form.mobile.startsWith('1')) {
        this.$message.error("请输入正确的手机号码");
      } else {
        this.$emit('confirmHmPhoneForm', this.form.mobile)
        this.form.mobile = ''
      }
    }
  },
}
</script>
<style scoped lang="less">
.el-dialog__wrapper{


  /deep/ .hm-phone-dialog-mobile, /deep/ .hm-phone-dialog {
    width: 800px;
    height: 400px;
    background: #ffffff;
    border-radius: 18px;
    .el-dialog__header {
      // height: 0px !important  ;
      // padding: 0;
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      height: 100%;
      .name-check-result-dialog-body {
        height: 100%;
        padding-bottom: 30px;
        box-sizing: border-box;
        position: relative;
        .close-icon {
          position: absolute;
          top: 20px;
          right: 20px;
        }
        .title {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1a1a1a;
          display: flex;
          align-items: center;
          padding: 40px 0px 0px 35px;
          box-sizing: border-box;
          margin-bottom: 30px;
        }
        .title::before {
          content: '';
          display: block;
          width: 4px;
          height: 19px;
          background: #2871ff;
          border-radius: 2px;
          margin-right: 10px;
        }
        .dialog-tip{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 700px;
          height: 40px;
          margin: 0 auto;
          margin-bottom: 60px;
          background: #F0F8FF;
          border-radius: 4px;
          border: 2px solid rgba(59,130,246,0.36);
          .i-icon{
            display: flex;
            align-items: center;
            margin-left: 20px;
            margin-right: 10px;
            .el-icon-info{
              color:#088BFF;
            }
          }

          .hint{
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #102A55;
          }
        }
        .form-item {
          width: 400px;
          height: 50px;
          // background: #FFFFFF;
          border-radius: 10px;
          margin: 0 auto;
          margin-bottom: 40px;
          .el-input {
            width: 100%;
            height: 100%;
            .el-input__inner {
              height: 100%;
              // background: transparent;
              font-size: 15px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #060b4c;
              // border: 1px solid rgba(255, 255, 255, 0.25);
              border-radius: 10px;
              &::placeholder {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(16, 42, 85, 0.4);
              }
            }
          }
        }
        .submit-btn{
          width: 160px;
          height: 50px;
          margin: 0 auto;
          background: #2871FF;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #FFFFFF;
        }
      }
    }
  }
  /deep/ .hm-phone-dialog-mobile{
    width: 690px;
    height: 540px;
    .el-dialog__body {
      
      .name-check-result-dialog-body {
        .form-item {
          width: 500px;
          height: 100px;
          margin-top:40px;
          .el-input {
            width: 100%;
            height: 100%;
            .el-input__inner {
              height: 100%;
              // background: transparent;
              font-size: 30px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #060b4c;
              // border: 1px solid rgba(255, 255, 255, 0.25);
              border-radius: 10px;
              &::placeholder {
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(16, 42, 85, 0.4);
              }
            }
          }
        }
        .title {
          font-size: 36px;
        }
        .title::before {
          content: '';
          display: block;
          width: 8px;
          height: 38px;
        }
        .close-icon {
          width: 50px;
          height: 50px;
        }
        .dialog-tip{
          width: 540px;
          font-size: 20px;
          height: 50px;
          .i-icon{
          }

          .hint{
            font-size: 20px;
          }
        }
        .submit-btn{
          width: 320px;
          height: 90px;
          font-size: 30px;
        }
      }
    }
    
  }
}
</style>
