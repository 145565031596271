<template>
  <div class="qr-code">
    <el-dialog
      title=""
      :visible.sync="QrCodeDialogVisible"
      destroy-on-close
      custom-class="qr-code-dialog"
      top="30vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="qr-code-dialog-body">
        <img
          class="close-icon"
          src="@/assets/images/home/close.png"
          alt=""
          @click="closeQr"
        />
        <div class="qr"></div>
        <div class="text">扫码查询核名结果</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import homeApi from '@/api/home.api.js'
import auth from '@/utils/auth.js'
export default {
  props: ['QrCodeDialogVisible', 'qrUrl'],
  data() {
    return {}
  },
  created() {},
  methods: {
    closeQr() {
      this.$emit('closeQr')
    },
  },
}
</script>
<style scoped lang="less">
.qr-code {
  /deep/ .qr-code-dialog {
    width: 560px;
    height: 360px;
    background: #ffffff;
    border-radius: 18px;
    .el-dialog__header {
      // height: 0px !important  ;
      // padding: 0;
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      height: 100%;
      .qr-code-dialog-body {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .close-icon {
          position: absolute;
          top: 12px;
          right: 12px;
        }
        .qr {
          width: 200px;
          height: 200px;
          background-image: url('../../assets/images/gshm/xcxm.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          img {
            width: 140px;
          }
        }
        .text {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #102a55;
          // line-height: 40px;
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
